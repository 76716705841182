import React from 'react';
import { gql, useMutation, useQuery, useLazyQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';

import { useLoading } from '../../context/useLoading';
import Users from './Users';
import WithMainLayout from '../../hocs/withMainLayout';
import Loading from '../shared/Loading';

const GET_USERS = gql`
  query find($query: UsersQuery, $pagination: PaginationQuery) {
    findUsers(queryUsers: $query, pagination: $pagination) {
      users {
        id
        username
        nickname
        email
        role
        points
        level {
          id
          name
          degree
        }
        sex
        hero {
          id
        }
        shortCode
        tickets
        birthday
        phoneNumber
        userAvatarURL
      }
      total
      limit
      page
      pages
    }
  }
`;

const GET_DATA = gql`
  query getData {
    levels {
      id
      name
    }
    heros {
      id
      name
      identifier
    }
    usersByRole(role: "trainer") {
      id
      role
      nickname
    }
  }
`;

const ADD_USER = gql`
  mutation addUser($input: UserInput) {
    addUser(input: $input) {
      id
      username
      nickname
      email
      role
      points
      level {
        id
        name
      }
      sex
      hero {
        id
      }
      tickets
      birthday
      phoneNumber
      userAvatarURL
      isGuestClient
    }
  }
`;

const EDIT_USER = gql`
  mutation editUser($id: ID!, $input: EditUserInput) {
    editUser(id: $id, input: $input) {
      id
      username
      nickname
      email
      role
      points
      level {
        id
        name
      }
      sex
      hero {
        id
      }
      tickets
      birthday
      phoneNumber
      userAvatarURL
    }
  }
`;

const GET_CLUBS = gql`
  query clubs {
    clubs {
      id
      name
    }
  }
  `;

const UsersContainer = () => {
  const { showLoading, hideLoading } = useLoading();
  const [users, setUsers] = React.useState([]);
  const [levels, setLevels] = React.useState([]);
  const [heroes, setHeroes] = React.useState([]);
  const [trainers, setTrainers] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [usersCnt, setUsersCnt] = React.useState(0);
  const [searchNickname, setSearchNickname] = React.useState(null);
  const { data, loading, error, refetch } = useQuery(GET_DATA);
  const [addUser, { error: errorAddUser }] = useMutation(ADD_USER, {
    onCompleted: () => {
      hideLoading();
      toast.success('Пользователь добавлен');
    },
  });
  const [editUser, { data: editUserData }] = useMutation(EDIT_USER);
  const [clubs, setClubs] = React.useState();
  const [userClub, setUserClub] = React.useState();

  const [
    getUsers,
    {
      loading: loadingUsers,
      error: errorUsers,
      data: dataUsers,
      refetch: refetchUsers,
    },
  ] = useLazyQuery(GET_USERS);

  const { data: clubsData, loading: clubsLoading } = useQuery(GET_CLUBS);

  React.useEffect(() => {
    if (errorAddUser) {
      console.log(errorAddUser);
    }
  }, [errorAddUser]);

  React.useEffect(() => {
    if (data && !error && !loading) {
      setLevels(data.levels);
      setHeroes(data.heros);
      setTrainers(data.usersByRole);
    }
  }, [data, loading, error, editUserData]);

  React.useEffect(() => {
    if (dataUsers && dataUsers.findUsers && !loadingUsers) {
      setUsers(dataUsers.findUsers.users);
      setUsersCnt(dataUsers.findUsers.total);
    } else if (errorUsers) {
      console.log(`error `, errorUsers);
    }
  }, [dataUsers, loadingUsers, errorUsers]);

  React.useEffect(() => {
    getUsers({
      pagination: {
        page: page,
        limit: 10,
      },
    });
  }, []);

  React.useEffect(() => {
    if (clubsData && !clubsLoading && users) {  
      setClubs(clubsData.clubs);
      setUserClub(clubsData?.clubs.find(item => item?.id === users?.club?.id));
    }
  }, [clubsData, clubsLoading, users]);

  React.useEffect(() => {
    refetchUsers({
      query: {
        text: searchNickname,
      },
      pagination: {
        page: searchNickname ? 1 : page,
        limit: 10,
      },
    });    
  }, [page, searchNickname]);

  const addUserHandler = (form, closeFunc) => {
    form
      .validateFields()
      .then((val) => {
        form.resetFields();
        showLoading();
        addUser({
          variables: {
            input: {
              nickname: val.nickname,
              password: val.password,
              username: val.email,
              email: val.email,
              sex: val.sex,
              hero: val.hero,
              level: val.level,
              phoneNumber: val.phoneNumber,
              birthday: val.birthday,
              role: val.role,
              userAvatarURL: val.userAvatarURL,
              club: val.club,
              isGuestClient: val.isGuestClient
            },
          },
        });
      })
      .then(() => {
        closeFunc(false);
      });
  };

  const editUserHandler = (form, userId, closeFunc) => {
    showLoading();
    form
      .validateFields()
      .then((val) => {
        editUser({
          variables: {
            id: userId,
            input: {
              nickname: val.nickname,
              username: val.email,
              email: val.email,
              phoneNumber: val.phoneNumber,
              sex: val.sex,
              birthday: dayjs(val.birthday).format('DD-MM-YYYY'),
              hero: val.hero,
              level: val.level,
              role: val.role,
              club: val.club,
            },
          },
        });
      })
      .then(() => {
        hideLoading();
        closeFunc(false);
        toast.success('Пользователь изменен');
      });
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Users
      users={users}
      levels={levels}
      heroes={heroes}
      trainers={trainers}
      addUser={addUser}
      refetch={refetch}
      addUserHandler={addUserHandler}
      editUserHandler={editUserHandler}
      page={page}
      setPage={setPage}
      usersCnt={usersCnt}
      setSearchNickname={setSearchNickname}
      searchNickname={searchNickname}
      clubs={clubs}
    />
  );
};

export default WithMainLayout(UsersContainer);

import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Form, Input, Select, Checkbox } from 'antd';
import { PlusOutlined, LinkOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';

import StyledTooltip from '../shared/StyledTooltip';
import showConfirm from '../shared/DeleteConfirm';

const { TextArea } = Input;
const FormItem = Form.Item;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};

const EditEvent = ({
  visible,
  onCancel,
  title,
  editEvent,
  currentClub,
  programSets,
  trainers,
  notificationChangeTrainer,
  setEditEvent,
  addEventClick,
  updateEventClick,
  addMultipleEventsClick,
  updateMultipleEventsClick,
  deleteMultipleEventsClick,
}) => {
  const [form] = Form.useForm();
  const [isCombined, setIsCombined] = React.useState(
    editEvent?.events?.length > 1
  );

  const getTooltip = (text, fullText) => {
    return <StyledTooltip {...{ text, fullText }} />;
  };

  return (
    <Modal
      open={visible}
      title={title}
      footer={null}
      //   okText="Создать"
      //   cancelText="Отмена"
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          zone: editEvent?.zoneId,
          programSet:
            editEvent?.events?.length > 0
              ? editEvent?.events[0]?.programSet?.id
              : null,
          secondProgramSet:
            editEvent?.events?.length > 0
              ? editEvent?.events[1]?.programSet?.id
              : null,
          trainer:
            editEvent?.events?.length > 0
              ? editEvent?.events[0]?.trainer?.id
              : null,
          status:
            editEvent?.events?.length > 0 ? editEvent?.events[0]?.status : null,
          limitStation:
            editEvent?.events?.length > 0
              ? editEvent?.events[0]?.limitStation
              : null,
        }}
      >
        <FormItem
          key="zone"
          label={
            <>
              <div
                style={{
                  fontFamily: 'GraphikLCGMedium',
                  fontSize: 16,
                  color: 'rgba(0, 0, 0, 0.5)',
                }}
              >
                Зал
              </div>
            </>
          }
          name="zone"
          rules={[
            {
              required: true,
              message: `Пожалуйста, выберите зал`,
            },
          ]}
        >
          <Select
            placeholder="Зал"
            showSearch
            disabled={!currentClub?.id}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {currentClub?.zones.map((zone) => (
              <Select.Option key={zone.id} value={zone.id}>
                {zone.name}
              </Select.Option>
            ))}
          </Select>
        </FormItem>
        <FormItem
          key="programSet"
          label={
            <div
              style={{
                fontFamily: 'GraphikLCGMedium',
                fontSize: 16,
                color: 'rgba(0, 0, 0, 0.5)',
              }}
            >
              Занятие
            </div>
          }
          name="programSet"
          rules={[
            {
              required: true,
              message: `Пожалуйста, выберите занятие`,
            },
          ]}
        >
          <Select
            placeholder="Выберите занятие"
            showSearch
            disabled={!currentClub?.id}
            filterOption={(input, option) =>
              option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
            }
          >
            {programSets
              .filter(
                (programSet) => programSet?.zone?.id === editEvent?.zoneId
              )
              .map((programSet) => (
                <Select.Option key={programSet.id} value={programSet.id}>
                  {programSet.name}
                </Select.Option>
              ))}
          </Select>
        </FormItem>
        <Form.Item valuePropName="checked">
          <Checkbox
            style={{
              fontFamily: 'GraphikLCGRegular',
              fontSize: 12,
            }}
            checked={isCombined}
            onChange={(e) => {
              setIsCombined(e.target.checked);
            }}
          >
            Совмещенное занятие
          </Checkbox>
        </Form.Item>
        {isCombined && (
          <FormItem
            key="secondProgramSet"
            label={
              <>
                <div
                  onClick={() => {
                    if (editEvent?.events?.length > 1) {
                      const win = window.open(
                        `/events/${editEvent?.events[1].id}`,
                        '_blank'
                      );
                      win.focus();
                    }
                  }}
                  style={{
                    fontFamily: 'GraphikLCGMedium',
                    fontSize: 16,
                    color: 'rgba(0, 0, 0, 0.5)',
                  }}
                >
                  2-е занятие
                </div>
              </>
            }
            name="secondProgramSet"
            rules={[
              {
                required: true,
                message: `Пожалуйста, выберите занятие`,
              },
            ]}
          >
            <Select
              placeholder="Выберите занятие"
              showSearch
              disabled={!currentClub?.id}
              filterOption={(input, option) =>
                option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >=
                0
              }
            >
              {programSets
                .filter(
                  (programSet) => programSet?.zone?.id === editEvent?.zoneId
                )
                .map((programSet) => (
                  <Select.Option key={programSet.id} value={programSet.id}>
                    {programSet.name}
                  </Select.Option>
                ))}
            </Select>
          </FormItem>
        )}

        <FormItem
          key="Trainer"
          label={
            <>
              <div
                style={{
                  fontFamily: 'GraphikLCGMedium',
                  fontSize: 16,
                  color: 'rgba(0, 0, 0, 0.5)',
                }}
              >
                Тренер
              </div>
            </>
          }
          name="trainer"
          rules={[
            {
              required: true,
              message: `Пожалуйста, выберите тренера`,
            },
          ]}
        >
          <Select placeholder="Выберите тренера" showSearch>
            {trainers
              //   ?.filter((trainer) => trainer?.club?.id === currentClub?.id)
              .map((trainer) => (
                <Select.Option key={trainer?.id} value={trainer?.id}>
                  {trainer?.nickname}
                </Select.Option>
              ))}
          </Select>
        </FormItem>
        <FormItem
          key="Статус"
          label={
            <>
              <div
                style={{
                  fontFamily: 'GraphikLCGMedium',
                  fontSize: 16,
                  color: 'rgba(0, 0, 0, 0.5)',
                }}
              >
                Статус
              </div>
            </>
          }
          name="status"
          rules={[
            {
              required: true,
              message: `Пожалуйста, выберите статус`,
            },
          ]}
        >
          <Select placeholder="Cтатус">
            <Select.Option key="preview" value="preview">
              Черновик
            </Select.Option>
            <Select.Option key="published" value="published">
              Опубликован
            </Select.Option>
          </Select>
        </FormItem>
        <FormItem
          key="limitStation"
          label={
            <>
              <div
                style={{
                  fontFamily: 'GraphikLCGMedium',
                  fontSize: 16,
                  color: 'rgba(0, 0, 0, 0.5)',
                }}
              >
                Лимит станции
              </div>
            </>
          }
          name="limitStation"
        >
          <Input placeholder="Лимит станции" type="number" />
        </FormItem>

        {editEvent?.events?.length > 0 && (
          <div
            style={{
              marginBottom: 8,
            }}
          >
            <button
              style={{
                width: '100%',
                color: '#FB5F5F',
                borderRadius: 8,
                paddingTop: 16,
                paddingBottom: 16,
                fontFamily: 'GraphikLCGMedium',
                fontSize: 14,
                backgroundColor: 'white',
                border: 'none',
                cursor: 'pointer',
              }}
              onClick={() => {
                showConfirm(() => {
                  deleteMultipleEventsClick(
                    editEvent?.events?.map((event) => event.id)
                  );
                  form.resetFields();
                  onCancel();
                });
              }}
            >
              Удалить занятие
            </button>
          </div>
        )}

        <div
          style={{
            marginBottom: 8,
          }}
        >
          <button
            style={{
              width: '100%',
              color: 'white',
              borderRadius: 8,
              paddingTop: 16,
              paddingBottom: 16,
              fontFamily: 'GraphikLCGMedium',
              fontSize: 14,
              backgroundColor: 'rgba(111, 44, 255, 1)',
              border: 'none',
              cursor: 'pointer',
            }}
            onClick={() => {
              form
                .validateFields()
                .then((values) => {
                  form.resetFields();
                  console.log('values', values);
                  if (editEvent?.events?.length > 0) {
                    if (values.secondProgramSet) {
                      if (editEvent && editEvent?.events[0]?.trainer && editEvent?.events[0]?.id !== values.trainer) {
                      notificationChangeTrainer({
                        variables: {
                          eventId: editEvent?.events[0]?.id,
                           },
                        });
                      } 
                      updateMultipleEventsClick([
                        {
                          id: editEvent?.events[0]?.id,
                          event: {
                            startTime: dayjs(editEvent?.startTime),
                            endTime: dayjs(editEvent?.startTime).add(
                              1,
                              'hours'
                            ),
                            trainer: values.trainer,
                            programSet: values.programSet,
                            zone: editEvent?.zoneId,
                            club: currentClub?.id,
                            status: values.status,
                            limitStation: values.limitStation
                              ? parseInt(values.limitStation)
                              : null,
                          },
                        },
                        {
                          id: editEvent?.events[1]?.id,
                          event: {
                            startTime: dayjs(editEvent?.startTime),
                            endTime: dayjs(editEvent?.startTime).add(
                              1,
                              'hours'
                            ),
                            trainer: values.trainer,
                            programSet: values.secondProgramSet,
                            zone: editEvent?.zoneId,
                            club: currentClub?.id,
                            status: values.status,
                            limitStation: values.limitStation
                              ? parseInt(values.limitStation)
                              : null,
                          },
                        },
                      ]);
                    } else {
                      if (editEvent && editEvent?.events[0]?.trainer && editEvent?.events[0]?.id !== values.trainer) {
                      notificationChangeTrainer({
                        variables: {
                          eventId: editEvent?.events[0]?.id,
                           },
                        });
                      } 
                      updateEventClick(editEvent?.events[0]?.id, {
                        startTime: dayjs(editEvent?.startTime),
                        endTime: dayjs(editEvent?.startTime).add(1, 'hours'),
                        trainer: values.trainer,
                        programSet: values.programSet,
                        zone: editEvent?.zoneId,
                        club: currentClub?.id,
                        status: values.status,
                        limitStation: values.limitStation
                          ? parseInt(values.limitStation)
                          : null,
                      });
                    }
                  } else {
                    if (values.secondProgramSet) {
                      addMultipleEventsClick([
                        {
                          startTime: dayjs(editEvent?.startTime),
                          endTime: dayjs(editEvent?.startTime).add(1, 'hours'),
                          trainer: values.trainer,
                          programSet: values.programSet,
                          zone: editEvent?.zoneId,
                          club: currentClub?.id,
                          status: values.status,
                          limitStation: values.limitStation
                            ? parseInt(values.limitStation)
                            : null,
                        },
                        {
                          startTime: dayjs(editEvent?.startTime),
                          endTime: dayjs(editEvent?.startTime).add(1, 'hours'),
                          trainer: values.trainer,
                          programSet: values.secondProgramSet,
                          zone: editEvent?.zoneId,
                          club: currentClub?.id,
                          status: values.status,
                          limitStation: values.limitStation
                            ? parseInt(values.limitStation)
                            : null,
                        },
                      ]);
                    } else {
                      addEventClick({
                        startTime: dayjs(editEvent?.startTime),
                        endTime: dayjs(editEvent?.startTime).add(1, 'hours'),
                        trainer: values.trainer,
                        programSet: values.programSet,
                        zone: editEvent?.zoneId,
                        club: currentClub?.id,
                        status: values.status,
                        limitStation: values.limitStation
                          ? parseInt(values.limitStation)
                          : null,
                      });
                    }
                  }
                  onCancel();
                  // handleCreate(parseValues(values))
                })
                .catch((info) => {
                  // eslint-disable-next-line no-console
                  console.log('Validate Failed:', info);
                });
            }}
          >
            {editEvent?.events?.length > 0 ? 'Сохранить' : 'Добавить'}
          </button>
        </div>
        {editEvent?.events?.length > 0 && (
          <div
            style={{
              marginBottom: 8,
            }}
          >
            <button
              style={{
                width: '100%',
                color: 'black',
                borderRadius: 8,
                paddingTop: 8,
                paddingBottom: 8,
                fontFamily: 'GraphikLCGMedium',
                fontSize: 14,
                backgroundColor: 'white',
                border: '1px solid rgba(0, 0, 0, 0.10)',
                cursor: 'pointer',
              }}
              onClick={() => {
                if (editEvent?.events?.length > 0) {
                  addMultipleEventsClick(
                    editEvent?.events?.map((event) => {
                      return {
                        startTime: dayjs(event?.startTime).add(1, 'week'),
                        endTime: dayjs(event?.startTime)
                          .add(1, 'week')
                          .add(1, 'hours'),
                        trainer: event.trainer.id,
                        programSet: event.programSet.id,
                        zone: event?.programSet?.zone.id,
                        club: currentClub?.id,
                        status: 'preview',
                        limitStation: event?.limitStation
                          ? parseInt(event.limitStation)
                          : null,
                      };
                    })
                  );
                }

                form.resetFields();
                onCancel();
              }}
            >
              Повторить на след. неделю
            </button>
          </div>
        )}
      </Form>
    </Modal>
  );
};

EditEvent.propTypes = {
  editHero: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    countSales: PropTypes.number,
    tickets: PropTypes.number,
    backgroundURL: PropTypes.string,
    identifier: PropTypes.string,
    sex: PropTypes.string,
    history: PropTypes.string,
    avatarURL: PropTypes.string,
    bwAvatarURL: PropTypes.string,
    imageURL: PropTypes.string,
    heroLevels: PropTypes.shape(),
  }).isRequired,
  title: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  levels: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default EditEvent;

import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { MenuItem } from '@mui/material';
import MuiSelect from '@mui/material/Select';
import { Spin, Badge } from 'antd';
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  LinkOutlined,
} from '@ant-design/icons';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';

import EditEvent from './EditEventModal';
import PublishModal from './PublishModal';
import CopyEventsModal from './CopyEventsModal';

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

// new feats

const CalendarContainerNew = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  background: white;
  height: 85%;
  overflow: auto;
  border-radius: 8px;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
`;

const TdZone = styled.th`
  border: 1px solid #ddd;
  text-align: center;
  border-top: none;
`;

const TdDay = styled.td`
  height: 32px;
  text-align: center;
`;

const TdTime = styled.td`
  height: 18px;
  text-align: center;
`;
const TdEvent = styled.td`
  height: 32px;
`;

const TrainingText = styled.div`
  font-family: 'GraphikLCGMedium';
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
`;

const TrainingTime = styled.div`
  font-family: 'GraphikLCGRegular';
  font-size: 10px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
`;
const TrainerName = styled.div`
  font-family: 'GraphikLCGMedium';
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
`;

const Header = styled.div`
  padding: 16px;
  display: flex;
  justify-content: space-between;
`;

const LoadingContainer = styled.div`
  z-index: 1000;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(247, 251, 255, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const enumerateDaysBetweenDates = (startDate, endDate) => {
  let date = [];
  while (dayjs(startDate) <= dayjs(endDate)) {
    date.push(startDate);
    startDate = dayjs(startDate)
      .add(1, 'days')

      .format('YYYY-MM-DD');
  }
  return date;
};

const allColors = {
  fullBody: 'blue',
  upperBody: 'green',
  legs: 'orange',
  functional: 'lime',
  gluteLab: 'magenta',
  push: 'red',
  pull: 'red',
  armBlast: 'magenta',
  endGame: 'gold',
  assessment: 'cyan',
  education: 'skyblue',
  guest: 'purple',
  strength: 'purple',
  streetWorkout: 'purple',
  bootcamp: 'skyblue',
};

const Events = ({
  events,
  programSets,
  clubs,
  trainers,
  handleAddEvent,
  handleUpdateEvent,
  handleCopyEvent,
  handleAddMultipleEvents,
  handleUpdateMultipleEvents,
  handleDeleteMultipleEvents,
  // new fields
  user,
  currentClub,
  setCurrentClub,
  currentClubId,
  setCurrentClubId,
  date,
  setDate,
  startTime,
  setStartTime,
  endTime,
  setEndTime,
  loading,
  notificationChangeTrainer,
}) => {
  const [modalVisible, setModalVisible] = React.useState(false);
  const [publishModalVisible, setPublishModalVisible] = React.useState(false);
  const [copyEventsVisible, setCopyEventsVisible] = React.useState(false);
  const [editEvent, setEditEvent] = React.useState(null);
  const [group, setGroup] = React.useState([]);

  const viewTimes = ['00', '10', '20', '30', '40', '50'];
  const [weekDays, setWeekDays] = React.useState(
    enumerateDaysBetweenDates(
      dayjs(startTime).startOf('week'),
      dayjs(startTime).endOf('week')
    )
  );

  React.useEffect(() => {
    setWeekDays(
      enumerateDaysBetweenDates(
        dayjs(startTime).startOf('week'),
        dayjs(startTime).endOf('week')
      )
    );
  }, [startTime]);

  const getTimes = (startDate, endDate) => {
    let date = [];
    while (dayjs(startDate) <= dayjs(endDate)) {
      date.push(startDate.format('HH:mm'));
      startDate = dayjs(startDate).add(5, 'minutes');
    }
    return date;
  };
  const timesOfDay = getTimes(
    dayjs().startOf('day').add(6, 'hours'),
    dayjs().endOf('day')
  );

  React.useEffect(() => {
    if (events?.length > 0) {
      const zoneGroups = events.reduce((zoneGroups, event) => {
        const zoneId = event?.programSet?.zone?.id;
        if (!zoneGroups[zoneId]) {
          zoneGroups[zoneId] = [];
        }

        const dayString = dayjs(event?.startTime).format('YYYY-MM-DD');
        zoneGroups[zoneId][dayString] = zoneGroups[zoneId][dayString] || [];

        const timeString = dayjs(event?.startTime).format('HH:mm');
        zoneGroups[zoneId][dayString][timeString] =
          zoneGroups[zoneId][dayString][timeString] || [];
        zoneGroups[zoneId][dayString][timeString].push(event);

        return zoneGroups;
      }, {});
      setGroup(zoneGroups);
    }
  }, [events]);

  return (
    <>
      <CalendarContainerNew>
        {loading && (
          <LoadingContainer>
            <Spin />
          </LoadingContainer>
        )}
        <>
          <Header>
            <div style={{ display: 'flex' }}>
              <button
                onClick={() => {
                  setStartTime(dayjs().startOf('week'));
                  setEndTime(dayjs().endOf('week'));
                }}
                style={{
                  cursor: 'pointer',
                  borderRadius: '8px',
                  fontFamily: 'GraphikLCGMedium',
                  fontSize: '14px',
                  padding: '8px 12px ',
                  border: '1px solid rgba(0, 0, 0, 0.10)',
                  background: '#FFF',
                }}
              >
                Текущая неделя
              </button>
              <div
                style={{
                  display: 'flex',
                  marginLeft: '16px',
                }}
              >
                <button
                  onClick={() => {
                    setStartTime(
                      dayjs(startTime).add(-7, 'days').startOf('week')
                    );
                    setEndTime(dayjs(startTime).add(-7, 'days').endOf('week'));
                  }}
                  style={{
                    cursor: 'pointer',
                    borderRadius: '8px',
                    fontFamily: 'GraphikLCGMedium',
                    fontSize: '14px',
                    padding: '8px 12px ',
                    border: '1px solid rgba(0, 0, 0, 0.10)',
                    background: '#FFF',
                  }}
                >
                  <ArrowLeftOutlined />
                </button>
                <button
                  onClick={() => {
                    setStartTime(
                      dayjs(startTime).add(7, 'days').startOf('week')
                    );
                    setEndTime(dayjs(startTime).add(7, 'days').endOf('week'));
                  }}
                  style={{
                    cursor: 'pointer',
                    borderRadius: '8px',
                    fontFamily: 'GraphikLCGMedium',
                    fontSize: '14px',
                    padding: '8px 12px ',
                    border: '1px solid rgba(0, 0, 0, 0.10)',
                    background: '#FFF',
                    marginLeft: '8px',
                  }}
                >
                  <ArrowRightOutlined />
                </button>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: 16,
                    fontFamily: 'GraphikLCGMedium',
                    fontSize: 24,
                  }}
                >
                  {dayjs(startTime).format('DD MMM').slice(0, -1)} -{' '}
                  {dayjs(endTime).format('DD MMM').slice(0, -1)}
                </div>
                {currentClubId && user?.role === 'admin' && (
                  <MuiSelect
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                    defaultValue={currentClubId}
                    displayEmpty
                    disableUnderline
                    style={{
                      fontFamily: 'GraphikLCGMedium',
                      width: 150,
                      fontSize: 14,
                      marginLeft: 12,
                    }}
                  >
                    {clubs?.map((item) => {
                      return (
                        <MenuItem
                          key={item?.id}
                          value={item?.id}
                          style={{
                            fontFamily: 'GraphikLCGMedium',
                            fontSize: 14,
                          }}
                          onClick={() => {
                            setCurrentClub(item);
                            setCurrentClubId(item.id);
                          }}
                        >
                          {item?.name}
                        </MenuItem>
                      );
                    })}
                  </MuiSelect>
                )}
              </div>
            </div>
            <div style={{ display: 'flex' }}>
              {dayjs() < dayjs(endTime) && (
                <button
                  onClick={() => {
                    setCopyEventsVisible(true);
                  }}
                  style={{
                    borderRadius: '8px',
                    fontFamily: 'GraphikLCGMedium',
                    fontSize: '14px',
                    padding: '8px 12px ',
                    border: '1px solid rgba(0, 0, 0, 0.10)',
                    background: '#FFF',
                    cursor: 'pointer',
                  }}
                >
                  Скопировать неделю
                </button>
              )}

              <button
                onClick={() => {
                  const previewEvents = events?.filter(
                    (event) => event.status === 'preview'
                  );

                  if (previewEvents?.length > 0) {
                    setPublishModalVisible(true);
                  }
                }}
                style={{
                  borderRadius: '8px',
                  fontFamily: 'GraphikLCGMedium',
                  fontSize: '14px',
                  padding: '8px 12px ',
                  border: '1px solid rgba(0, 0, 0, 0.10)',
                  background: '#FFF',
                  marginLeft: 16,
                  color:
                    events?.filter((event) => event.status === 'preview')
                      ?.length > 0
                      ? 'rgba(0, 0, 0, 1)'
                      : 'rgba(0, 0, 0, 0.1)',
                  cursor: 'pointer',
                }}
              >
                Опубликовать
              </button>
            </div>
          </Header>
          <StyledTable>
            <thead
              style={{
                position: 'sticky',
                top: '0',
                zIndex: '1',
                background: 'white',
              }}
            >
              <tr>
                <TdDay
                  rowspan="2"
                  style={{
                    width: '16px',
                  }}
                ></TdDay>
                {weekDays?.map((date, index) => {
                  const text =
                    dayjs(date).format('dd DD')[0].toUpperCase() +
                    dayjs(date).format('dd')[1] +
                    ' ' +
                    dayjs(date).format('DD');
                  return (
                    <TdDay
                      colSpan={currentClub?.zones?.length || 3}
                      style={{
                        width: '72px',
                      }}
                    >
                      <div
                        style={{
                          fontFamily: 'GraphikLCGMedium',
                          fontSize: 14,
                          color: 'rgba(0, 0, 0, 0.5)',
                        }}
                      >
                        {text}
                      </div>
                    </TdDay>
                  );
                })}
              </tr>
              <tr>
                {weekDays?.map((date, index) => {
                  return currentClub?.zones?.map((zone) => {
                    return (
                      <TdZone
                        title={zone?.name}
                        style={{
                          fontFamily: 'GraphikLCGRegular',
                          fontSize: 12,
                          borderLeft: 'none',
                          width: '72px',
                          background:
                            index % 2 === 1
                              ? 'rgba(0, 0, 0, 0.05)'
                              : 'rgba(0, 0, 0, 0)',
                        }}
                      >
                        {zone?.name[0]}
                      </TdZone>
                    );
                  });
                })}
              </tr>
            </thead>
            <tbody>
              {timesOfDay?.map((time, timeIndex) => {
                const colums = weekDays?.map((day, dayIndex) => {
                  const asd = currentClub?.zones?.map((zone, zoneIndex) => {
                    let eventData = group[zone.id]
                      ? group[zone.id][dayjs(day).format('YYYY-MM-DD')]
                        ? group[zone.id][dayjs(day).format('YYYY-MM-DD')]
                        : []
                      : [];

                    if (eventData[time]) {
                      let isEducation = false;
                      eventData[time]?.map((event) => {
                        if (
                          event?.programSet?.type === 'education' ||
                          event?.programSet?.type === 'assessment'
                        ) {
                          isEducation = true;
                        }
                      });
                      if (isEducation) {
                        return (
                          <TdEvent
                            rowspan="3"
                            onClick={() => {
                              const newData = {
                                zoneId: zone.id,
                                events: eventData[time],
                                startTime: dayjs(
                                  `${dayjs(day).format('YYYY-MM-DD')}T${time}`
                                ),
                                endTime: dayjs(
                                  `${dayjs(day).format('YYYY-MM-DD')}T${time}`
                                ).add(60, 'minutes'),
                              };

                              setEditEvent(newData);
                              setModalVisible(true);
                            }}
                            style={{
                              width: '72px',
                              cursor: 'pointer',
                              borderLeft: timeIndex === 0 && 'none',
                              background: 'rgba(248, 247, 248, 1)',
                              border: '1px solid rgba(0, 0, 0, 0.10)',
                            }}
                          >
                            <div
                              style={{
                                height: '100%',
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',

                                borderTop: `4px solid ${
                                  allColors[
                                    eventData[time][0]?.programSet?.type
                                  ]
                                }`,
                              }}
                            >
                              <div>
                                {eventData[time][0]?.status === 'preview' && (
                                  <Badge.Ribbon
                                    text="Demo"
                                    color="pink"
                                    style={{
                                      fontFamily: 'GraphikLCGMedium',
                                      fontSize: '10px',
                                      paddingLeft: 4,
                                      paddingRight: 4,
                                    }}
                                  />
                                )}
                                {/* {eventData[time][0]?.availableStatus ===
                                  'heroChallenge' && (
                                  <Badge.Ribbon
                                    text="HeroChallenge"
                                    color="pink"
                                    style={{
                                      fontFamily: 'GraphikLCGMedium',
                                      fontSize: '10px',
                                      paddingLeft: 4,
                                      paddingRight: 4,
                                    }}
                                  />
                                )} */}

                                <TrainingText
                                  title={eventData[time]?.map((data, index) => {
                                    return `${data?.programSet?.name}${
                                      eventData[time]?.length > 0 &&
                                      index === 0 &&
                                      index !== eventData[time]?.length - 1
                                        ? '/'
                                        : ''
                                    }`;
                                  })}
                                >
                                  {eventData[time]?.map((data, index) => {
                                    return `${data?.programSet?.name}${
                                      eventData[time]?.length > 0 &&
                                      index === 0 &&
                                      index !== eventData[time]?.length - 1
                                        ? '/'
                                        : ''
                                    }`;
                                  })}
                                </TrainingText>
                                <TrainingTime>
                                  {dayjs(eventData[time][0].startTime).format(
                                    'HH:mm'
                                  )}
                                </TrainingTime>
                              </div>

                              <div>
                                <TrainerName>
                                  {eventData[time][0]?.trainer?.nickname}
                                </TrainerName>
                              </div>
                            </div>
                          </TdEvent>
                        );
                      }
                      return (
                        <TdEvent
                          rowspan="12"
                          onClick={() => {
                            const newData = {
                              zoneId: zone.id,
                              events: eventData[time],
                              startTime: dayjs(
                                `${dayjs(day).format('YYYY-MM-DD')}T${time}`
                              ),
                              endTime: dayjs(
                                `${dayjs(day).format('YYYY-MM-DD')}T${time}`
                              ).add(60, 'minutes'),
                            };

                            setEditEvent(newData);
                            setModalVisible(true);
                          }}
                          style={{
                            width: '72px',
                            cursor: 'pointer',
                            borderLeft: timeIndex === 0 && 'none',
                            background: 'rgba(248, 247, 248, 1)',
                            border: '1px solid rgba(0, 0, 0, 0.10)',
                          }}
                        >
                          <div
                            style={{
                              height: '100%',
                              width: '100%',
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'space-between',

                              borderTop: `4px solid ${
                                allColors[eventData[time][0]?.programSet?.type]
                              }`,
                            }}
                          >
                            <div>
                              {eventData[time][0]?.status === 'preview' && (
                                <Badge.Ribbon
                                  text="demo"
                                  color="pink"
                                  style={{
                                    fontFamily: 'GraphikLCGMedium',
                                    fontSize: '10px',
                                    paddingLeft: 4,
                                    paddingRight: 4,
                                  }}
                                />
                              )}
                              {eventData[time][0]?.availableStatus ===
                                'heroChallenge' && (
                                <Badge.Ribbon
                                  text="HC"
                                  color="purple"
                                  style={{
                                    fontFamily: 'GraphikLCGMedium',
                                    fontSize: '10px',
                                    paddingLeft: 4,
                                    paddingRight: 4,
                                  }}
                                />
                              )}

                              <TrainingText
                                title={eventData[time]?.map((data, index) => {
                                  return `${data?.programSet?.name}${
                                    eventData[time]?.length > 0 &&
                                    index === 0 &&
                                    index !== eventData[time]?.length - 1
                                      ? '/'
                                      : ''
                                  }`;
                                })}
                              >
                                {eventData[time]?.map((data, index) => {
                                  return `${data?.programSet?.name}${
                                    eventData[time]?.length > 0 &&
                                    index === 0 &&
                                    index !== eventData[time]?.length - 1
                                      ? '/'
                                      : ''
                                  }`;
                                })}
                              </TrainingText>
                              <TrainingTime>
                                {dayjs(eventData[time][0].startTime).format(
                                  'HH:mm'
                                )}
                              </TrainingTime>
                            </div>

                            <div>
                              <TrainerName>
                                {eventData[time][0]?.trainer?.nickname}
                              </TrainerName>
                            </div>
                          </div>
                        </TdEvent>
                      );
                    } else if (
                      eventData[
                        dayjs(`${dayjs(day).format('YYYY-MM-DD')}T${time}`)
                          .add(-5, 'minute')
                          .format('HH:mm')
                      ]?.length > 0 ||
                      eventData[
                        dayjs(`${dayjs(day).format('YYYY-MM-DD')}T${time}`)
                          .add(-10, 'minute')
                          .format('HH:mm')
                      ]?.length > 0
                    ) {
                      return null;
                    } else if (
                      eventData[
                        dayjs(`${dayjs(day).format('YYYY-MM-DD')}T${time}`)
                          .add(-15, 'minute')
                          .format('HH:mm')
                      ]?.length > 0
                    ) {
                      let isEducation = false;
                      eventData[
                        dayjs(`${dayjs(day).format('YYYY-MM-DD')}T${time}`)
                          .add(-15, 'minute')
                          .format('HH:mm')
                      ]?.map((event) => {
                        if (
                          event?.programSet?.type === 'education' ||
                          event?.programSet?.type === 'assessment'
                        ) {
                          isEducation = true;
                        }
                      });
                      if (isEducation) {
                        return (
                          <TdTime
                            onClick={() => {
                              const newData = {
                                zoneId: zone.id,
                                startTime: dayjs(
                                  `${dayjs(day).format('YYYY-MM-DD')}T${time}`
                                ),
                                endTime: dayjs(
                                  `${dayjs(day).format('YYYY-MM-DD')}T${time}`
                                ).add(60, 'minutes'),
                              };

                              setEditEvent(newData);
                              setModalVisible(true);
                            }}
                            style={{
                              border: '1px solid #ddd',
                              background:
                                dayjs(day).format('YYYY-MM-DD') ===
                                  dayjs().format('YYYY-MM-DD') &&
                                'rgba(255, 220, 40, .15)',
                              borderLeft: timeIndex === 0 && 'none',
                            }}
                          ></TdTime>
                        );
                      }
                      return null;
                    } else if (
                      eventData[
                        dayjs(`${dayjs(day).format('YYYY-MM-DD')}T${time}`)
                          .add(-20, 'minute')
                          .format('HH:mm')
                      ]?.length > 0
                    ) {
                      let isEducation = false;
                      eventData[
                        dayjs(`${dayjs(day).format('YYYY-MM-DD')}T${time}`)
                          .add(-20, 'minute')
                          .format('HH:mm')
                      ]?.map((event) => {
                        if (
                          event?.programSet?.type === 'education' ||
                          event?.programSet?.type === 'assessment'
                        ) {
                          isEducation = true;
                        }
                      });
                      if (isEducation) {
                        return (
                          <TdTime
                            onClick={() => {
                              const newData = {
                                zoneId: zone.id,
                                startTime: dayjs(
                                  `${dayjs(day).format('YYYY-MM-DD')}T${time}`
                                ),
                                endTime: dayjs(
                                  `${dayjs(day).format('YYYY-MM-DD')}T${time}`
                                ).add(60, 'minutes'),
                              };

                              setEditEvent(newData);
                              setModalVisible(true);
                            }}
                            style={{
                              border: '1px solid #ddd',
                              background:
                                dayjs(day).format('YYYY-MM-DD') ===
                                  dayjs().format('YYYY-MM-DD') &&
                                'rgba(255, 220, 40, .15)',
                              borderLeft: timeIndex === 0 && 'none',
                            }}
                          ></TdTime>
                        );
                      }
                      return null;
                    } else if (
                      eventData[
                        dayjs(`${dayjs(day).format('YYYY-MM-DD')}T${time}`)
                          .add(-25, 'minute')
                          .format('HH:mm')
                      ]?.length > 0
                    ) {
                      let isEducation = false;
                      eventData[
                        dayjs(`${dayjs(day).format('YYYY-MM-DD')}T${time}`)
                          .add(-25, 'minute')
                          .format('HH:mm')
                      ]?.map((event) => {
                        if (
                          event?.programSet?.type === 'education' ||
                          event?.programSet?.type === 'assessment'
                        ) {
                          isEducation = true;
                        }
                      });
                      if (isEducation) {
                        return (
                          <TdTime
                            onClick={() => {
                              const newData = {
                                zoneId: zone.id,
                                startTime: dayjs(
                                  `${dayjs(day).format('YYYY-MM-DD')}T${time}`
                                ),
                                endTime: dayjs(
                                  `${dayjs(day).format('YYYY-MM-DD')}T${time}`
                                ).add(60, 'minutes'),
                              };

                              setEditEvent(newData);
                              setModalVisible(true);
                            }}
                            style={{
                              border: '1px solid #ddd',
                              background:
                                dayjs(day).format('YYYY-MM-DD') ===
                                  dayjs().format('YYYY-MM-DD') &&
                                'rgba(255, 220, 40, .15)',
                              borderLeft: timeIndex === 0 && 'none',
                            }}
                          ></TdTime>
                        );
                      }
                      return null;
                    } else if (
                      eventData[
                        dayjs(`${dayjs(day).format('YYYY-MM-DD')}T${time}`)
                          .add(-30, 'minute')
                          .format('HH:mm')
                      ]?.length > 0
                    ) {
                      let isEducation = false;
                      eventData[
                        dayjs(`${dayjs(day).format('YYYY-MM-DD')}T${time}`)
                          .add(-30, 'minute')
                          .format('HH:mm')
                      ]?.map((event) => {
                        if (
                          event?.programSet?.type === 'education' ||
                          event?.programSet?.type === 'assessment'
                        ) {
                          isEducation = true;
                        }
                      });
                      if (isEducation) {
                        return (
                          <TdTime
                            onClick={() => {
                              const newData = {
                                zoneId: zone.id,
                                startTime: dayjs(
                                  `${dayjs(day).format('YYYY-MM-DD')}T${time}`
                                ),
                                endTime: dayjs(
                                  `${dayjs(day).format('YYYY-MM-DD')}T${time}`
                                ).add(60, 'minutes'),
                              };

                              setEditEvent(newData);
                              setModalVisible(true);
                            }}
                            style={{
                              border: '1px solid #ddd',
                              background:
                                dayjs(day).format('YYYY-MM-DD') ===
                                  dayjs().format('YYYY-MM-DD') &&
                                'rgba(255, 220, 40, .15)',
                              borderLeft: timeIndex === 0 && 'none',
                            }}
                          ></TdTime>
                        );
                      }
                      return null;
                    } else if (
                      eventData[
                        dayjs(`${dayjs(day).format('YYYY-MM-DD')}T${time}`)
                          .add(-30, 'minute')
                          .format('HH:mm')
                      ]?.length > 0
                    ) {
                      let isEducation = false;
                      eventData[
                        dayjs(`${dayjs(day).format('YYYY-MM-DD')}T${time}`)
                          .add(-30, 'minute')
                          .format('HH:mm')
                      ]?.map((event) => {
                        if (
                          event?.programSet?.type === 'education' ||
                          event?.programSet?.type === 'assessment'
                        ) {
                          isEducation = true;
                        }
                      });
                      if (isEducation) {
                        return (
                          <TdTime
                            onClick={() => {
                              const newData = {
                                zoneId: zone.id,
                                startTime: dayjs(
                                  `${dayjs(day).format('YYYY-MM-DD')}T${time}`
                                ),
                                endTime: dayjs(
                                  `${dayjs(day).format('YYYY-MM-DD')}T${time}`
                                ).add(60, 'minutes'),
                              };

                              setEditEvent(newData);
                              setModalVisible(true);
                            }}
                            style={{
                              border: '1px solid #ddd',
                              background:
                                dayjs(day).format('YYYY-MM-DD') ===
                                  dayjs().format('YYYY-MM-DD') &&
                                'rgba(255, 220, 40, .15)',
                              borderLeft: timeIndex === 0 && 'none',
                            }}
                          ></TdTime>
                        );
                      }
                      return null;
                    } else if (
                      eventData[
                        dayjs(`${dayjs(day).format('YYYY-MM-DD')}T${time}`)
                          .add(-35, 'minute')
                          .format('HH:mm')
                      ]?.length > 0
                    ) {
                      let isEducation = false;
                      eventData[
                        dayjs(`${dayjs(day).format('YYYY-MM-DD')}T${time}`)
                          .add(-35, 'minute')
                          .format('HH:mm')
                      ]?.map((event) => {
                        if (
                          event?.programSet?.type === 'education' ||
                          event?.programSet?.type === 'assessment'
                        ) {
                          isEducation = true;
                        }
                      });
                      if (isEducation) {
                        return (
                          <TdTime
                            onClick={() => {
                              const newData = {
                                zoneId: zone.id,
                                startTime: dayjs(
                                  `${dayjs(day).format('YYYY-MM-DD')}T${time}`
                                ),
                                endTime: dayjs(
                                  `${dayjs(day).format('YYYY-MM-DD')}T${time}`
                                ).add(60, 'minutes'),
                              };

                              setEditEvent(newData);
                              setModalVisible(true);
                            }}
                            style={{
                              border: '1px solid #ddd',
                              background:
                                dayjs(day).format('YYYY-MM-DD') ===
                                  dayjs().format('YYYY-MM-DD') &&
                                'rgba(255, 220, 40, .15)',
                              borderLeft: timeIndex === 0 && 'none',
                            }}
                          ></TdTime>
                        );
                      }
                      return null;
                    } else if (
                      eventData[
                        dayjs(`${dayjs(day).format('YYYY-MM-DD')}T${time}`)
                          .add(-35, 'minute')
                          .format('HH:mm')
                      ]?.length > 0
                    ) {
                      let isEducation = false;
                      eventData[
                        dayjs(`${dayjs(day).format('YYYY-MM-DD')}T${time}`)
                          .add(-35, 'minute')
                          .format('HH:mm')
                      ]?.map((event) => {
                        if (
                          event?.programSet?.type === 'education' ||
                          event?.programSet?.type === 'assessment'
                        ) {
                          isEducation = true;
                        }
                      });
                      if (isEducation) {
                        return (
                          <TdTime
                            onClick={() => {
                              const newData = {
                                zoneId: zone.id,
                                startTime: dayjs(
                                  `${dayjs(day).format('YYYY-MM-DD')}T${time}`
                                ),
                                endTime: dayjs(
                                  `${dayjs(day).format('YYYY-MM-DD')}T${time}`
                                ).add(60, 'minutes'),
                              };

                              setEditEvent(newData);
                              setModalVisible(true);
                            }}
                            style={{
                              border: '1px solid #ddd',
                              background:
                                dayjs(day).format('YYYY-MM-DD') ===
                                  dayjs().format('YYYY-MM-DD') &&
                                'rgba(255, 220, 40, .15)',
                              borderLeft: timeIndex === 0 && 'none',
                            }}
                          ></TdTime>
                        );
                      }
                      return null;
                    } else if (
                      eventData[
                        dayjs(`${dayjs(day).format('YYYY-MM-DD')}T${time}`)
                          .add(-40, 'minute')
                          .format('HH:mm')
                      ]?.length > 0
                    ) {
                      let isEducation = false;
                      eventData[
                        dayjs(`${dayjs(day).format('YYYY-MM-DD')}T${time}`)
                          .add(-40, 'minute')
                          .format('HH:mm')
                      ]?.map((event) => {
                        if (
                          event?.programSet?.type === 'education' ||
                          event?.programSet?.type === 'assessment'
                        ) {
                          isEducation = true;
                        }
                      });
                      if (isEducation) {
                        return (
                          <TdTime
                            onClick={() => {
                              const newData = {
                                zoneId: zone.id,
                                startTime: dayjs(
                                  `${dayjs(day).format('YYYY-MM-DD')}T${time}`
                                ),
                                endTime: dayjs(
                                  `${dayjs(day).format('YYYY-MM-DD')}T${time}`
                                ).add(60, 'minutes'),
                              };

                              setEditEvent(newData);
                              setModalVisible(true);
                            }}
                            style={{
                              border: '1px solid #ddd',
                              background:
                                dayjs(day).format('YYYY-MM-DD') ===
                                  dayjs().format('YYYY-MM-DD') &&
                                'rgba(255, 220, 40, .15)',
                              borderLeft: timeIndex === 0 && 'none',
                            }}
                          ></TdTime>
                        );
                      }
                      return null;
                    } else if (
                      eventData[
                        dayjs(`${dayjs(day).format('YYYY-MM-DD')}T${time}`)
                          .add(-45, 'minute')
                          .format('HH:mm')
                      ]?.length > 0
                    ) {
                      let isEducation = false;
                      eventData[
                        dayjs(`${dayjs(day).format('YYYY-MM-DD')}T${time}`)
                          .add(-45, 'minute')
                          .format('HH:mm')
                      ]?.map((event) => {
                        if (
                          event?.programSet?.type === 'education' ||
                          event?.programSet?.type === 'assessment'
                        ) {
                          isEducation = true;
                        }
                      });
                      if (isEducation) {
                        return (
                          <TdTime
                            onClick={() => {
                              const newData = {
                                zoneId: zone.id,
                                startTime: dayjs(
                                  `${dayjs(day).format('YYYY-MM-DD')}T${time}`
                                ),
                                endTime: dayjs(
                                  `${dayjs(day).format('YYYY-MM-DD')}T${time}`
                                ).add(60, 'minutes'),
                              };

                              setEditEvent(newData);
                              setModalVisible(true);
                            }}
                            style={{
                              border: '1px solid #ddd',
                              background:
                                dayjs(day).format('YYYY-MM-DD') ===
                                  dayjs().format('YYYY-MM-DD') &&
                                'rgba(255, 220, 40, .15)',
                              borderLeft: timeIndex === 0 && 'none',
                            }}
                          ></TdTime>
                        );
                      }
                      return null;
                    } else if (
                      eventData[
                        dayjs(`${dayjs(day).format('YYYY-MM-DD')}T${time}`)
                          .add(-50, 'minute')
                          .format('HH:mm')
                      ]?.length > 0
                    ) {
                      let isEducation = false;
                      eventData[
                        dayjs(`${dayjs(day).format('YYYY-MM-DD')}T${time}`)
                          .add(-50, 'minute')
                          .format('HH:mm')
                      ]?.map((event) => {
                        if (
                          event?.programSet?.type === 'education' ||
                          event?.programSet?.type === 'assessment'
                        ) {
                          isEducation = true;
                        }
                      });
                      if (isEducation) {
                        return (
                          <TdTime
                            onClick={() => {
                              const newData = {
                                zoneId: zone.id,
                                startTime: dayjs(
                                  `${dayjs(day).format('YYYY-MM-DD')}T${time}`
                                ),
                                endTime: dayjs(
                                  `${dayjs(day).format('YYYY-MM-DD')}T${time}`
                                ).add(60, 'minutes'),
                              };

                              setEditEvent(newData);
                              setModalVisible(true);
                            }}
                            style={{
                              border: '1px solid #ddd',
                              background:
                                dayjs(day).format('YYYY-MM-DD') ===
                                  dayjs().format('YYYY-MM-DD') &&
                                'rgba(255, 220, 40, .15)',
                              borderLeft: timeIndex === 0 && 'none',
                            }}
                          ></TdTime>
                        );
                      }
                      return null;
                    } else if (
                      eventData[
                        dayjs(`${dayjs(day).format('YYYY-MM-DD')}T${time}`)
                          .add(-55, 'minute')
                          .format('HH:mm')
                      ]?.length > 0
                    ) {
                      let isEducation = false;
                      eventData[
                        dayjs(`${dayjs(day).format('YYYY-MM-DD')}T${time}`)
                          .add(-55, 'minute')
                          .format('HH:mm')
                      ]?.map((event) => {
                        if (
                          event?.programSet?.type === 'education' ||
                          event?.programSet?.type === 'assessment'
                        ) {
                          isEducation = true;
                        }
                      });
                      if (isEducation) {
                        return (
                          <TdTime
                            onClick={() => {
                              const newData = {
                                zoneId: zone.id,
                                startTime: dayjs(
                                  `${dayjs(day).format('YYYY-MM-DD')}T${time}`
                                ),
                                endTime: dayjs(
                                  `${dayjs(day).format('YYYY-MM-DD')}T${time}`
                                ).add(60, 'minutes'),
                              };

                              setEditEvent(newData);
                              setModalVisible(true);
                            }}
                            style={{
                              border: '1px solid #ddd',
                              background:
                                dayjs(day).format('YYYY-MM-DD') ===
                                  dayjs().format('YYYY-MM-DD') &&
                                'rgba(255, 220, 40, .15)',
                              borderLeft: timeIndex === 0 && 'none',
                            }}
                          ></TdTime>
                        );
                      }
                      return null;
                    } else {
                      return (
                        <TdTime
                          onClick={() => {
                            const newData = {
                              zoneId: zone.id,
                              startTime: dayjs(
                                `${dayjs(day).format('YYYY-MM-DD')}T${time}`
                              ),
                              endTime: dayjs(
                                `${dayjs(day).format('YYYY-MM-DD')}T${time}`
                              ).add(60, 'minutes'),
                            };

                            setEditEvent(newData);
                            setModalVisible(true);
                          }}
                          style={{
                            border: '1px solid #ddd',
                            background:
                              dayjs(day).format('YYYY-MM-DD') ===
                                dayjs().format('YYYY-MM-DD') &&
                              'rgba(255, 220, 40, .15)',
                            borderLeft: timeIndex === 0 && 'none',
                          }}
                        ></TdTime>
                      );
                    }
                  });
                  return asd;
                });
                const columsFilter = colums
                  ?.filter((data) => data !== null)
                  ?.map((data) => data);
                return (
                  <tr>
                    <TdTime style={{}}>
                      {viewTimes.includes(time.slice(-2)) ? time : ' '}
                    </TdTime>
                    {columsFilter?.map((data) => data)}
                  </tr>
                );
              })}
            </tbody>
          </StyledTable>
        </>
      </CalendarContainerNew>

      {modalVisible && editEvent !== null && (
        <EditEvent
          notificationChangeTrainer={notificationChangeTrainer}
          visible={modalVisible}
          onCancel={() => {
            setEditEvent(null);
          }}
          title={
            <>
              <div
                style={{
                  fontFamily: 'GraphikLCGMedium',
                  fontSize: 16,
                  cursor: 'pointer',
                  display: 'flex',
                }}
              >
                {editEvent?.events?.length > 0
                  ? editEvent?.events?.map((data, index) => {
                      return (
                        <div
                          style={{
                            marginLeft: index > 0 && 4,
                          }}
                          onClick={() => {
                            const win = window.open(
                              `/events/${data.id}`,
                              '_blank'
                            );
                            win.focus();
                          }}
                        >
                          {data?.programSet?.name}
                          {editEvent?.events?.length > 0 &&
                          index === 0 &&
                          index !== editEvent?.events?.length - 1
                            ? ' / '
                            : ' '}
                        </div>
                      );
                    })
                  : 'Новое занятие'}
              </div>
              <div
                style={{
                  fontFamily: 'GraphikLCGRegular',
                  fontSize: 16,
                }}
              >
                {dayjs(editEvent?.startTime).format('dd')[0].toUpperCase()}
                {dayjs(editEvent?.startTime).format('dd')[1]}
                {dayjs(editEvent?.startTime).format(', HH:mm')}
              </div>
            </>
          }
          editEvent={editEvent}
          currentClub={currentClub}
          setEditEvent={setEditEvent}
          programSets={programSets}
          trainers={trainers}
          addEventClick={handleAddEvent}
          updateEventClick={handleUpdateEvent}
          addMultipleEventsClick={handleAddMultipleEvents}
          updateMultipleEventsClick={handleUpdateMultipleEvents}
          deleteMultipleEventsClick={handleDeleteMultipleEvents}
        />
      )}

      {publishModalVisible && (
        <PublishModal
          visible={publishModalVisible}
          onCancel={() => {
            setPublishModalVisible(null);
          }}
          startTime={startTime}
          endTime={endTime}
          previewEvents={events?.filter((event) => event?.status === 'preview')}
          events={events}
          updateMultipleEventsClick={handleUpdateMultipleEvents}
        />
      )}

      {copyEventsVisible && (
        <CopyEventsModal
          visible={copyEventsVisible}
          onCancel={() => {
            setCopyEventsVisible(null);
          }}
          startTime={startTime}
          endTime={endTime}
          currentClub={currentClub}
          events={events}
          addMultipleEventsClick={handleAddMultipleEvents}
        />
      )}
    </>
  );
};

Events.propTypes = {
  events: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  programSets: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  clubs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  trainers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      nickname: PropTypes.string,
    })
  ).isRequired,
  addEventClick: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  handleCopyEvent: PropTypes.func.isRequired,
};

export default withRouter(Events);

import {
  AlertOutlined,
  CalendarOutlined,
  CrownOutlined,
  DashboardOutlined,
  DollarCircleOutlined,
  DollarOutlined,
  DropboxOutlined,
  GiftOutlined,
  InsertRowAboveOutlined,
  LogoutOutlined,
  MessageOutlined,
  MoneyCollectOutlined,
  NodeIndexOutlined,
  NotificationOutlined,
  PicCenterOutlined,
  ProfileOutlined,
  RadarChartOutlined,
  RobotOutlined,
  SettingOutlined,
  ShopOutlined,
  SnippetsOutlined,
  StarOutlined,
  TeamOutlined,
  ThunderboltOutlined,
  TrophyOutlined,
  UserOutlined,
  ScheduleOutlined,
  OrderedListOutlined,
} from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';

import Loading from '../pages/shared/Loading';
import { Menu } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useAuth } from '../context/useAuth';

// Menu Definitions
const adminMenu = [
  {
    name: 'Боты', // New submenu
    icon: RobotOutlined,
    subItems: [
      { link: '/promptDescriptions', name: 'Параметры бота' },
      { link: '/promptNews', name: 'Новости для бота' },
      { link: '/promptTrainers', name: 'Информация о тренере' },
      { link: '/promptTrainings', name: 'Информация о тренировке' },
      {
        link: '/generatorAIShadowMessage',
        name: 'Генератор сообщении от AIShadow',
      },
    ],
  },
  {
    name: 'Пользователи',
    icon: TeamOutlined,
    subItems: [{ link: '/users', name: 'Пользователи' }],
  },
  {
    name: 'Товары',
    icon: TeamOutlined,
    subItems: [{ link: '/products', name: 'Товары' }],
  },
  {
    name: 'Оценки',
    icon: StarOutlined,
    subItems: [{ link: '/trainerReviews', name: 'Оценки' }],
  },
  {
    name: 'События',
    icon: CalendarOutlined,
    subItems: [
      { link: '/receptionEvents', name: 'События ресепшна' },
      { link: '/trainerEvents', name: 'События тренера' },
    ],
  },
  {
    name: `Hero's pass`,
    icon: ScheduleOutlined,
    subItems: [{ link: '/heroPasses', name: `Hero's pass` }],
  },
  {
    name: 'Конверсия ПШ',
    icon: TeamOutlined,
    subItems: [{ link: '/conversionContainer', name: 'Конверсия ПШ' }],
  },
  {
    name: 'FAQ',
    icon: SnippetsOutlined,
    subItems: [{ link: '/FaqContainer', name: 'FAQ' }],
  },
  {
    name: 'Задачи', // New submenu
    icon: CalendarOutlined,
    subItems: [{ link: '/events', name: 'События' }],
  },
  {
    name: 'Клиенты', // New submenu
    icon: TeamOutlined,
    subItems: [{ link: '/clientsContainer', name: 'Клиенты' }],
  },
  {
    name: 'Марафоны', // New submenu
    icon: TrophyOutlined,
    subItems: [
      { link: '/marathons', name: 'Марафоны' },
      { link: '/heroPathes', name: 'Пути Марафона' },
    ],
  },
  {
    name: 'Настройки', // New submenu
    icon: SettingOutlined,
    subItems: [
      { link: '/specialBookings', name: 'Акции' },
      { link: '/autoNotifications', name: 'Автоматические нотификации' },
      { link: '/coefficientsCompress', name: 'Настройки приложения' },
    ],
  },
  {
    name: 'Награды', // New submenu
    icon: RadarChartOutlined,
    subItems: [
      { link: '/awards', name: 'Награды' },
      { link: '/treasureChests', name: 'Сундуки' },
      { link: '/allDroppedRewards', name: 'Выпадения с сундуков' },
    ],
  },
  {
    name: 'Отчёты', // New submenu
    icon: DollarOutlined,
    subItems: [
      { link: '/salesReport', name: 'Отчёт по продажам' },
      { link: '/newsModals', name: 'Новости' },
    ],
  },
  {
    name: 'Персонажи', // New submenu
    icon: UserOutlined,
    subItems: [
      { link: '/heros', name: 'Персонажи' },
      { link: '/clubs', name: 'Клубы', icon: ShopOutlined },
    ],
  },
  {
    name: 'Платежи', // New submenu
    icon: MoneyCollectOutlined,
    subItems: [
      { link: '/payments', name: 'Пакеты' },
      { link: '/allUserPayments', name: 'Платежи клиентов' },
      { link: '/salesUsers', name: 'Платежи пользователей' },
      { link: '/salesPayments', name: 'Платежи' },
    ],
  },
  {
    name: 'Программы', // New submenu
    icon: ProfileOutlined,
    subItems: [
      { link: '/programSets', name: 'Программы' },
      { link: '/exercises', name: 'Упражнения', icon: ThunderboltOutlined },
      { link: '/levels', name: 'Уровни', icon: CrownOutlined },
    ],
  },
  {
    name: 'Сообщения', // New submenu
    icon: MessageOutlined,
    subItems: [
      { link: '/messages', name: 'Сообщения' },
      { link: '/notifications', name: 'Нотификация' },
      { link: '/createMessage', name: 'Создать Сообщения' },
    ],
  },
  {
    name: 'Поддержка', // New submenu
    icon: AlertOutlined,
    subItems: [
      { link: '/support', name: 'Тех. Поддержка' },
      { link: '/tickets', name: 'Служба поддержки' },
      { link: '/tickets/new', name: 'Создать тикет' },
      { link: '/supportCategories', name: 'Категории тех.поддержки' },
    ],
  },
  {
    name: 'Hero`s week', // New submenu
    icon: InsertRowAboveOutlined,
    subItems: [{ link: '/herosWeek', name: 'Hero`s week' }],
  },
];

// Other role menus (if needed)
const doctorMenu = [];
const trainerMenu = [
  {
    link: '/trainerEvents',
    name: 'События',
    icon: CalendarOutlined,
  },
  {
    link: '/trainerReviews',
    name: 'Оценки',
    icon: StarOutlined,
  },
];
const photographerMenu = [];
const receptionMenu = [
  {
    link: '/receptionEvents',
    name: 'События',
    icon: CalendarOutlined,
  },
];
const salespersonMenu = [
  {
    link: '/receptionEvents',
    name: 'События',
    icon: CalendarOutlined,
  },
  {
    link: '/users',
    name: 'Пользователи',
    icon: TeamOutlined,
  },
  {
    link: '/allUserPayments',
    name: 'Платежи клиентов',
    icon: MoneyCollectOutlined,
  },
  {
    link: '/salesUsers',
    name: 'Платежи пользователей',
    icon: MoneyCollectOutlined,
  },
  { link: '/events', name: 'Расписание', icon: CalendarOutlined },
  {
    link: '/salesPayments',
    name: 'Платежи клиентов',
    icon: MoneyCollectOutlined,
  },
  {
    link: '/clientsContainer',
    name: 'Клиенты',
    icon: TeamOutlined,
  },
  {
    link: '/marathons',
    name: 'Марафоны',
    icon: TrophyOutlined,
  },
  {
    link: '/conversionContainer',
    name: 'Конверсия ПШ',
    icon: TeamOutlined,
  },
  {
    link: '/herosWeek',
    name: 'Hero`s week',
    icon: InsertRowAboveOutlined,
  },
];
const accountantnMenu = [
  {
    link: '/salesReport',
    name: 'Отчёт по продажам',
    icon: DollarOutlined,
  },
];
const botManagerMenu = [
  {
    link: '/promptDescriptions',
    name: 'Параметры бота',
    icon: RobotOutlined,
  },
  {
    link: '/promptNews',
    name: 'Новости для бота',
    icon: SnippetsOutlined,
  },
  {
    link: '/generatorAIShadowMessage',
    name: 'Генератор сообщении от AIShadow',
    icon: OrderedListOutlined,
  },
];

// User role to menu mapping
const userToMenu = {
  admin: adminMenu,
  doctor: doctorMenu,
  trainer: trainerMenu,
  photographer: photographerMenu,
  reception: receptionMenu,
  salesperson: salespersonMenu,
  salesadmin: salespersonMenu,
  accountant: accountantnMenu,
  botManager: botManagerMenu,
};

// Main Menu Component
function MainMenu({ currentUrl }) {
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  if (!user) {
    return <Loading />;
  }

  const currentMenu = userToMenu[user?.role] || [];

  const onLogoutClick = () => {
    logout();
    navigate('/login');
  };

  return (
    <Menu selectedKeys={[currentUrl]} mode="inline" theme="light">
      {currentMenu.map((item) => {
        if (item.subItems) {
          return (
            <Menu.SubMenu
              key={item.name}
              icon={<item.icon />}
              title={item.name}
            >
              {item.subItems.map((subItem) => (
                <Menu.Item key={subItem.link}>
                  <Link to={subItem.link}>{subItem.name}</Link>
                </Menu.Item>
              ))}
            </Menu.SubMenu>
          );
        }
        return (
          <Menu.Item key={item.link} icon={<item.icon />}>
            <Link to={item.link}>{item.name}</Link>
          </Menu.Item>
        );
      })}

      <Menu.Item
        key="logout"
        icon={<LogoutOutlined />}
        onClick={onLogoutClick}
        danger
      >
        Выйти
      </Menu.Item>
    </Menu>
  );
}

MainMenu.propTypes = {
  currentUrl: PropTypes.string.isRequired,
};

export default MainMenu;

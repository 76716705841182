import React from 'react';
import PropTypes from 'prop-types';
import { Form, Select, Input, Spin, List, Button } from 'antd';
import styled from 'styled-components';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';

import StyledTooltip from '../shared/StyledTooltip';

const { TextArea } = Input;
const FormItem = Form.Item;

const Wrapper = styled.div`
  display: flex;
  background: #ffffff;
  padding: 24px;
  border-radius: 8px;
  justify-content: space-around;
`;

const FormContainer = styled.div`
  width: 600px;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #0000001a;
  height: 100%;
`;

const AIShadow = styled.div`
  width: 600px;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #0000001a;
`;
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const getTooltip = (text, fullText) => {
  return <StyledTooltip {...{ text, fullText }} />;
};

const GeneratorAIShadow = ({
  users,
  refetchUsers,
  loadingUsers,
  loading,
  userMarathonEvents,
  setSelectedUserId,
  setSelectedUserMEId,
  handleGenerate,
  messagesData,
}) => {
  const [form] = Form.useForm();

  const handleSearch = (value) => {
    refetchUsers({
      query: {
        phoneNumber: value,
      },
      pagination: {
        page: 1,
        limit: 20,
      },
    });
  };

  return (
    <Wrapper>
      <FormContainer>
        <Form
          form={form}
          layout="vertical"
          hideRequiredMark
          onFinish={(values) => {
            const { user, userMarathonEvent, promptDescription } = values;
            handleGenerate({
              userId: user,
              userMarathonEventId: userMarathonEvent,
              promptDescription: promptDescription,
            });
          }}
        >
          <FormItem
            key="user"
            label={getTooltip('Клиент', 'Клиент')}
            name="user"
          >
            <Select
              placeholder="Выберите Клиента"
              showSearch
              onSearch={handleSearch}
              onChange={(value) => {
                console.log('value', value);
                setSelectedUserId(value);
              }}
              filterOption={false}
              notFoundContent={loadingUsers ? <Spin size="small" /> : null}
            >
              {users?.map((user) => (
                <Select.Option key={user.id} value={user.id}>
                  {user.nickname}
                </Select.Option>
              ))}
            </Select>
          </FormItem>
          <FormItem
            key="userMarathonEvent"
            label={getTooltip('Марафоны Клиента', 'Марафоны Клиента')}
            name="userMarathonEvent"
          >
            <Select
              placeholder="Выберите Марафон Клиента"
              showSearch
              onSearch={handleSearch}
              onChange={(value) => {
                console.log('value', value);
                setSelectedUserMEId(value);
              }}
              filterOption={false}
              notFoundContent={loadingUsers ? <Spin size="small" /> : null}
            >
              {userMarathonEvents?.map((userMarathonEvent) => (
                <Select.Option
                  key={userMarathonEvent.id}
                  value={userMarathonEvent.id}
                >
                  {`"${userMarathonEvent?.marathon?.name}" c ${dayjs(
                    userMarathonEvent?.marathonEvent?.startTime
                  ).format('Do MMM YYYY')} до ${dayjs(
                    userMarathonEvent?.marathonEvent?.endTime
                  ).format('Do MMM YYYY')}`}
                </Select.Option>
              ))}
            </Select>
          </FormItem>
          <FormItem
            key="promptDescription"
            label={getTooltip('Prompt #1', 'Prompt #1')}
            name="promptDescription"
          >
            <TextArea maxLength={200} placeholder="Prompt #1" />
          </FormItem>

          <FormItem>
            <ButtonContainer>
              <Button type="primary" htmlType="submit" disabled={loading}>
                {loading ? <Spin size="small" /> : 'Сгенерировать'}
              </Button>
            </ButtonContainer>
          </FormItem>
        </Form>
      </FormContainer>

      <AIShadow>
        <List
          itemLayout="horizontal"
          dataSource={messagesData}
          header={<div>Сообщения от AIShadow</div>}
          loading={loading}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                title={<span>День: {item?.day}</span>}
                description={item?.text}
              />
            </List.Item>
          )}
        />
      </AIShadow>
    </Wrapper>
  );
};
GeneratorAIShadow.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      nickname: PropTypes.string,
    })
  ),
  refetchUsers: PropTypes.func,
  loadingUsers: PropTypes.bool,
  loading: PropTypes.bool,
  userMarathonEvents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      marathonEvent: PropTypes.shape({}),
      marathon: PropTypes.shape({}),
    })
  ),
  setSelectedUserId: PropTypes.string,
  setSelectedUserMEId: PropTypes.string,
  handleGenerate: PropTypes.func,
  messagesData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      nickname: PropTypes.string,
    })
  ),
};
export default GeneratorAIShadow;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { Input, Modal, DatePicker, Checkbox, Form, Select } from 'antd';
import StyledTooltip from '../shared/StyledTooltip';

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const AddPackageModal = ({
  modalVisible,
  onCancel,
  payments,
  currentTickets,
  userIsInvictusClient,
  handleAddTicketsBySales,
}) => {
  const [values, setValues] = React.useState({
    expiryDate: dayjs().format(),
    changeValue: 0,
    comment: '',
    payment: '',
  });

  const handleInputChange = (field, value) => {
    if (field === 'id') {
      const findSelectedPayment = payments.find((el) => el.id === value);

      setValues({
        ...values,
        changeValue: findSelectedPayment.countClasses,
        payment: value,
        [field]: value,
      });
    } else {
      setValues({
        ...values,
        [field]: value,
      });
    }
  };

  const getTooltip = (text, fullText) => {
    return <StyledTooltip {...{ text, fullText }} />;
  };

  const handleOkClick = () => {
    handleAddTicketsBySales({ values });
    onCancel();
    onCancel(false);
  };
  return (
    <Modal
      open={modalVisible}
      onCancel={onCancel}
      onOk={handleOkClick}
      cancelText="Отмена"
    >
      <MainContainer>
        <h2>Баланс: {currentTickets}</h2>
        <Form layout="vertical">
          <Form.Item>
            <label>
              {getTooltip(
                'Тип пакета',
                'Какой тип у билетов которые вы добавляете'
              )}
            </label>
            <Select
              mode="single"
              onChange={(value) => {
                handleInputChange('id', value);
              }}
              placeholder="Выберите пакет"
            >
              {payments.map((payment) => {
                if (userIsInvictusClient && payment?.ticketType === 'regular') {
                  if (
                    userIsInvictusClient &&
                    payment?.ticketType === 'regular' &&
                    payment.isActive
                  ) {
                    return (
                      <Select.Option
                        key={payment?.id}
                        value={payment?.id}
                        label={'sss'}
                      >
                        {payment?.name} ЧК | Стоимость: {payment?.cost}ТГ
                      </Select.Option>
                    );
                  }
                }
                if (
                  !userIsInvictusClient &&
                  payment?.ticketType === 'outsider'
                ) {
                  if (
                    !userIsInvictusClient &&
                    payment?.ticketType === 'outsider' &&
                    payment.isActive
                  ) {
                    return (
                      <Select.Option key={payment?.id} value={payment?.id}>
                        {payment?.name} НЕ ЧК | Стоимость: {payment?.cost}ТГ
                      </Select.Option>
                    );
                  }
                  return null;
                }
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <label>{getTooltip('Комментарий', 'Зачем добавили билеты?')}</label>
            <Input
              value={values?.comment}
              onChange={(e) => handleInputChange('comment', e.target.value)}
            />
          </Form.Item>
        </Form>
      </MainContainer>
    </Modal>
  );
};

AddPackageModal.propTypes = {
  currentTickets: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  modalVisible: PropTypes.bool.isRequired,
  payments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      cost: PropTypes.number.isRequired,
      isActive: PropTypes.bool.isRequired,
      ticketType: PropTypes.string.isRequired,
      countClasses: PropTypes.number.isRequired,
    })
  ).isRequired,
  userIsInvictusClient: PropTypes.bool.isRequired,
  handleAddTicketsBySales: PropTypes.func.isRequired,
};

export default AddPackageModal;

import { useLocation, useNavigate } from 'react-router-dom';
import { Layout } from 'antd';
import React from 'react';
import styled from 'styled-components';

import MainMenu from './MainMenu';
import Logo from '../pages/logo.svg';
import ArrowDown from '../images/svg/ArrowDown';
import ChatIconWithBC from '../images/svg/ChatIconWithBC';
import PhoneIconWithBC from '../images/svg/PhoneIconWithBC';
import EyeIconWithBC from '../images/svg/EyeIconWithBC';
import FutureBooking from '../images/svg/FutureBooking';
import BookedIcon from '../images/svg/BookedIcon';
import MissedBookingIcon from '../images/svg/MissedBookingIcon';
import InClubIcon from '../images/svg/InClubIcon';
import InClubTodayIcon from '../images/svg/InClubTodayIcon';
import WasInClubTodayIcon from '../images/svg/WasInClubTodayIcon';

const { Header, Content, Sider } = Layout;
const screenWidth = window.screen.width;

const Wrapper = styled.div`
  margin: 24px;
  height: 100vh;
`;
const LogoContainer = styled.div`
  box-sizing: border-box;
  background: #fff;
  padding: 24px 0px 16px 24px;
`;

const StyledSider = styled(Sider)`
  height: 100%;
`;

const StyledLayout = styled(Layout)`
  background-color: #fff;
`;

const StyledHeader = styled(Header)`
  background: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 24px;
`;
const StyledText = styled.div`
  padding-top: 24px;
  font-family: 'GraphikLCGMedium';
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  color: #262626;
`;
const TooltipWrapper = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  background: white;
  border: 1px solid #ccc;
  padding: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 194px;
  border-radius: 8px;
`;

const InfoIconWrapper = styled.div`
  position: relative;
  display: inline-block;
  margin-left: auto;
`;

const StyledButton = styled.button`
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #0000001a;
  color: black;
  font-size: 14px;
  font-family: 'GraphikLCGMedium';
  padding: 12px;
  align-items: center;
  display: flex;
`;
const TooltipItem = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
`;

const withMainLayout = (Page) => {
  return (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const currentUrl = location.pathname;

    const [currentHeader, setCurrentHeader] = React.useState('');
    const [isTooltipVisible, setTooltipVisibility] = React.useState(false);

    const adminMenu = [
      { link: '/events', name: 'События' },
      { link: '/users', name: 'Пользователи' },
      { link: '/programSets', name: 'Программы' },
      { link: '/exercises', name: 'Упражнения' },
      { link: '/levels', name: 'Уровни' },
      { link: '/heros', name: 'Персонажи' },
      { link: '/clubs', name: 'Клубы' },
      { link: '/payments', name: 'Пакеты' },
      { link: '/messages', name: 'Сообщения' },
      { link: '/awards', name: 'Награды' },
      // { link: '/seasons', name: 'Клановые войны' },
      // { link: '/contests', name: 'Конкурсы' },
      { link: '/notifications', name: 'Нотификация' },

      {
        link: '/trainerEvents',
        name: 'События',
      },
      {
        link: '/trainerReviews',
        name: 'Оценки',
      },
      {
        link: '/clientsContainer',
        name: 'Клиенты',
      },
      {
        link: '/receptionEvents',
        name: 'События',
      },
      {
        link: '/allUserPayments',
        name: 'Платежи клиентов',
      },
      {
        link: '/treasureChests',
        name: 'Сундуки',
      },
      {
        link: '/marathons',
        name: 'Марафоны',
      },
      {
        link: '/heroPathes',
        name: 'Пути Марфона',
      },
      {
        link: '/allDroppedRewards',
        name: 'Выпадения с сундуков',
      },
      {
        link: '/specialBookings',
        name: 'Акции',
      },
      {
        link: '/autoNotifications',
        name: 'Автоматические нотификации',
      },
      {
        link: '/coefficientsCompress',
        name: 'Настройки приложения',
      },
      {
        link: '/salesReport',
        name: 'Отчет по продажам',
      },
      {
        link: '/newsModals',
        name: 'Новости',
      },
      {
        link: '/products',
        name: 'Товары',
      },
      {
        link: '/products',
        name: 'Товары',
      },
      {
        link: '/heroPasses',
        name: 'HeroPass',
      },
      {
        link: '/promptDescriptions',
        name: 'Параметры бота',
      },
      {
        link: '/promptNews',
        name: 'Новости для бота',
      },
      {
        link: '/promptTrainers',
        name: 'Информация о тренере',
      },
      {
        link: '/promptTrainings',
        name: 'Информация о тренировке',
      },
      {
        link: '/generatorAIShadowMessage',
        name: 'Генератор сообщении от AIShadow',
      },
      {
        link: '/conversionContainer',
        name: 'Конверсия ПШ',
      },
      {
        link: '/faqContainer',
        name: 'FAQ',
      },
      {
        link: '/faqQuestions',
        name: 'Вопросы',
      },
      {
        link: '/support',
        name: 'Тех. поддержка',
      },
      {
        link: '/tickets',
        name: 'Служба поддержки',
      },
      {
        link: '/supportCategories',
        name: 'Категории тех.поддержки',
      },
      {
        link: '/herosWeek',
        name: 'Hero`s Week',
      },
    ];

    React.useEffect(() => {
      adminMenu.forEach((item) => {
        const array = currentUrl.split('/');
        const localname = '/' + array[1];
        if (localname === item.link) {
          setCurrentHeader(item.name);
        }
      });
    }, [currentUrl]);

    return (
      <StyledLayout>
        <StyledSider>
          <LogoContainer>
            <img src={Logo} alt="logo" />
          </LogoContainer>
          <MainMenu {...{ currentUrl, history: navigate, setCurrentHeader }} />
        </StyledSider>
        <Layout>
          <StyledHeader>
            <StyledText>{currentHeader}</StyledText>
            {currentHeader === 'Hero`s Week' ? (
              <InfoIconWrapper
                onMouseEnter={() => setTooltipVisibility(true)}
                onMouseLeave={() => setTooltipVisibility(false)}
              >
                <StyledButton>
                  Информация
                  <ArrowDown />
                </StyledButton>
                {isTooltipVisible && (
                  <TooltipWrapper>
                    <TooltipItem>
                      <ChatIconWithBC />
                      &nbsp; - Списались
                    </TooltipItem>
                    <TooltipItem>
                      <PhoneIconWithBC />
                      &nbsp; - Созвонились
                    </TooltipItem>
                    <TooltipItem>
                      <EyeIconWithBC />
                      &nbsp; - Увиделись вживую
                    </TooltipItem>
                    <TooltipItem>
                      <FutureBooking />
                      &nbsp; - Запись
                    </TooltipItem>
                    <TooltipItem>
                      <BookedIcon />
                      &nbsp; - Посещенное занятие
                    </TooltipItem>
                    <TooltipItem>
                      <MissedBookingIcon />
                      &nbsp; - Пропущенное занятие
                    </TooltipItem>
                    <TooltipItem>
                      <InClubIcon />
                      &nbsp; - Находится в зале
                    </TooltipItem>
                    <TooltipItem>
                      <InClubTodayIcon />
                      &nbsp; - Придёт сегодня
                    </TooltipItem>
                    <TooltipItem>
                      <WasInClubTodayIcon />
                      &nbsp; - Был сегодня
                    </TooltipItem>
                  </TooltipWrapper>
                )}
              </InfoIconWrapper>
            ) : null}
          </StyledHeader>
          <Content>
            <Wrapper style={{ width: screenWidth < 500 ? 1000 : '98%' }}>
              <Page {...props} />
            </Wrapper>
          </Content>
        </Layout>
      </StyledLayout>
    );
  };
};

export default withMainLayout;
